<template>
  <div v-loading="loading" class="bt">
    <div class="container">
      <el-row :gutter="5">
        <el-col :sm="8" v-if="!showSplitDataSelection">
          <div>
            <label>{{ currentEntity.name }} select type</label>
          </div>
          <div>
            <el-radio-group
              v-if="!this.field.show_only_totals"
              class="float-left"
              v-model="viewType"
              @change="configDataTableFields"
            >
              <el-radio label="total">Total</el-radio>
              <el-radio
                v-for="(op, i) in getDataTableColumFields"
                :key="i + '_op'"
                :label="op.key"
                >{{ op.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </el-col>
        <el-col :sm="4" class="mt-2" style="float: right">
          <el-input
            v-if="viewType != 'total' && !showSplitDataSelection"
            v-model="search"
            size="mini"
            placeholder="Type to search"
          />
        </el-col>
      </el-row>
      <el-row :gutter="5">
        <el-col :sm="24">
          <div
            v-if="viewType == 'total'"
            style="max-height: 60vh; overflow-y: auto"
            class="scrollable-div"
          >
            <el-table
              :data="data"
              style="width: 100%"
              border
              stripe
              class="sidemenu"
              ref="multipleEntityTable"
              @selection-change="handleSelectionChange"
              @select="checkSingleSelect"
              @select-all="checkMultiSelect"
              size="mini"
            >
              <el-table-column type="selection" width="40"> </el-table-column>
              <el-table-column
                :width="step.width ? step.width : 250"
                v-for="(step, index) of tableColums"
                :label="step.label"
                :key="step.id + index"
                fixed
                :prop="getPropValue(step)"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <div class="d-flex">
                    <div
                      class="pl-15"
                      v-if="
                        scope.row.entityData &&
                        scope.row.entityData[step.template_id] &&
                        scope.row.entityData[step.template_id][
                          `${step.id}_code`
                        ] &&
                        scope.row.entityData[step.template_id][
                          `${step.id}_code`
                        ].formatInternational
                      "
                    >
                      {{
                        scope.row.entityData[step.template_id][
                          `${step.id}_code`
                        ].formatInternational
                      }}
                    </div>

                    <div
                      class="pl-15"
                      v-else-if="
                        scope.row.entityData &&
                        scope.row.entityData[step.template_id] &&
                        scope.row.entityData[step.template_id][
                          `${step.id}_currency`
                        ] &&
                        scope.row.entityData[step.template_id][
                          `${step.id}_currency`
                        ]
                      "
                    >
                      <span
                        v-if="
                          scope.row.entityData[step.template_id][
                            `${step.id}_currency`
                          ] === 'USD'
                        "
                      >
                        {{
                          Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(
                            scope.row.entityData[step.template_id][step.id]
                          )
                        }}
                      </span>
                      <span
                        v-else-if="
                          scope.row.entityData[step.template_id][
                            `${step.id}_currency`
                          ] === 'INR'
                        "
                      >
                        {{
                          Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                          }).format(
                            scope.row.entityData[step.template_id][step.id]
                          )
                        }}
                      </span>
                      <span
                        v-else-if="
                          scope.row.entityData[step.template_id][
                            `${step.id}_currency`
                          ] === 'EUR'
                        "
                      >
                        {{
                          Intl.NumberFormat("en-IE", {
                            style: "currency",
                            currency: "EUR",
                          }).format(parseFloat(num).toFixed(2))
                        }}
                      </span>
                      <span
                        v-else-if="
                          scope.row.entityData[step.template_id][
                            `${step.id}_currency`
                          ] === 'CAD'
                        "
                      >
                        {{
                          Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "CAD",
                          }).format(
                            scope.row.entityData[step.template_id][step.id]
                          )
                        }}
                      </span>
                      <span
                        v-else-if="
                          scope.row.entityData[step.template_id][
                            `${step.id}_currency`
                          ] === 'AUD'
                        "
                      >
                        {{
                          Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "AUD",
                          }).format(
                            scope.row.entityData[step.template_id][step.id]
                          )
                        }}
                      </span>
                    </div>
                    <div v-else-if="step && step.type == 'DATE'">
                      {{ getFieldValue(scope.row, step) | globalDateFormat }}
                    </div>
                    <div v-else-if="step && step.type == 'DATE_TIME'">
                      {{
                        getFieldValue(scope.row, step)
                          | moment("MM-DD-YYYY hh:mm:ss A")
                      }}
                    </div>
                    <div v-else-if="step && step.type == 'FILE'">
                      <div
                        v-if="
                          getFieldValue(scope.row, step) &&
                          getFieldValue(scope.row, step).length &&
                          getFieldValue(scope.row, step).length <= 1
                        "
                      >
                        <el-button
                          type="text"
                          style="margin-top: -14px"
                          class="mb-1"
                        >
                          <p style="font-size: 13px">
                            Download <i class="el-icon-download"></i>
                          </p>
                        </el-button>
                      </div>
                      <div
                        v-else-if="
                          getFieldValue(scope.row, step) &&
                          getFieldValue(scope.row, step).length &&
                          getFieldValue(scope.row, step).length >= 1
                        "
                      >
                        <p
                          v-for="(file, index) of getFieldValue(
                            scope.row,
                            step
                          )"
                          :key="index"
                        >
                          <el-button
                            type="text"
                            style="margin-top: -35px; margin-bottom: -10%"
                          >
                            <p style="font-size: 13px">
                              {{ file.name | truncate(10, "...")
                              }}<i class="el-icon-download"></i>
                            </p>
                          </el-button>
                          <br />
                        </p>
                      </div>
                      <!-- </p> -->
                    </div>
                    <div
                      v-else-if="
                        step &&
                        step.type == 'DATE_TIME_RANGE' &&
                        scope.row.entityData[step.template_id] &&
                        scope.row.entityData[step.template_id][
                          `${step.id}_from`
                        ]
                      "
                    >
                      {{
                        scope.row.entityData[step.template_id][
                          `${step.id}_from`
                        ] | moment("MM-DD-YYYY hh:mm:ss A")
                      }}
                      (From ~ To)
                      {{
                        scope.row.entityData[step.template_id][`${step.id}_to`]
                          | moment("MM-DD-YYYY hh:mm:ss A")
                      }}
                    </div>
                    <div v-else-if="step && step.type == 'TIME'">
                      {{ getFieldValue(scope.row, step) | moment("hh:mm") }}
                    </div>
                    <div
                      v-else-if="
                        typeof getFieldValue(scope.row, step) == 'object' &&
                        getFieldValue(scope.row, step).lengths
                      "
                    >
                      {{ getFieldValue(scope.row, step).join(",") }}
                    </div>
                    <div v-else-if="step && step.type == 'AGGREGATE_FUNCTION'">
                      <p style="font-size: 13px">
                        {{
                          getCurrencyFormat(
                            getFieldValue(scope.row, step),
                            step.template_id + "#" + step.id
                          )
                        }}
                      </p>
                    </div>
                    <div
                      v-else-if="
                        step &&
                        (step.type == 'SIGNATURE' ||
                          step.inputType == 'SIGNATURE')
                      "
                    >
                      <img
                        v-if="getFieldValue(scope.row, step)"
                        :src="getFieldValue(scope.row, step)"
                        alt="IMG"
                        width="100"
                        height="60"
                      />
                      <p v-else>-</p>
                    </div>
                    <div class="pl-15" v-else>
                      {{ getFieldValue(scope.row, step) }}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-else>
            <template v-if="showSplitDataSelection">
              <div
                class="content-table"
                v-loading="showSplitDataSelectionLoading"
              >
                <div
                  style="max-height: 60vh; overflow-y: auto"
                  class="content-table-scroll"
                >
                  <table>
                    <tr>
                      <th
                        v-for="(step, index) of splitedFields"
                        :key="step.key + index"
                      >
                        {{ step.label }}
                      </th>
                      <th class="prevent-wrap">Action</th>
                    </tr>
                    <tr
                      v-for="(data, index) of splitedDataRow"
                      :key="'row_data_' + index"
                    >
                      <td
                        v-for="(step, i) of splitedFields"
                        :key="step.key + i"
                      >
                        <component
                          :fieldsData="fieldsData"
                          v-bind:is="step.components.execute"
                          :field="step"
                          :data="step"
                          :form="data"
                          :parentFormData="form"
                          :autoFillEntityData="
                            step.type == 'ENTITY' &&
                            step.entity_id &&
                            entityDataMapping &&
                            entityDataMapping[step.entity_id]
                              ? entityDataMapping[step.entity_id]
                              : null
                          "
                          :autoFillPrefeeredEntityData="
                            entityPrefeeredDataMapping &&
                            entityPrefeeredDataMapping[data.keyIndex]
                              ? entityPrefeeredDataMapping[data.keyIndex][
                                  step.key
                                ] || 'NOT_APPLY'
                              : null
                          "
                        >
                        </component>
                      </td>
                      <td>
                        <el-link
                          type="danger"
                          icon="el-icon-delete"
                          :underline="false"
                          :disabled="index == 0"
                          @click="deleteRow(index)"
                        ></el-link>
                      </td>
                    </tr>
                  </table>
                </div>
                <button
                  class="btn btn-sm btn-outline-primary m-lr-1 m-tb-3"
                  @click="addNewRow"
                >
                  <i class="fa fa-plus"></i> Add new
                </button>
              </div>
            </template>
            <template v-else>
              <div
                style="max-height: 60vh; overflow-y: auto"
                class="scrollable-div"
              >
                <el-table
                  :data="getDataTableData"
                  style="width: 100%"
                  class="sidemenu"
                  border
                  stripe
                  @selection-change="handleDataTableSelectionChange"
                  ref="multipleDataTable"
                  size="mini"
                >
                  <el-table-column type="selection" width="40">
                  </el-table-column>
                  <el-table-column
                    v-for="(step, index) of dataTableColums"
                    :label="step.label"
                    :key="step.key + index"
                    :prop="step.key"
                    :width="getColumnWidth(step)"
                  >
                    <template slot-scope="scope">
                      <div
                        v-if="
                          step.show_in_split &&
                          selectedRowSplittedDataByField(scope.row, step.key)
                            .length &&
                          !step.field_operation
                        "
                      >
                        Multiple
                      </div>
                      <component
                        v-else-if="
                          (step.show_in_split ||
                            step.editable_detailed_view == 'true' ||
                            step.editable_detailed_view == true ||
                            step.inputType == 'FORMULA') &&
                          !refresh
                        "
                        v-bind:is="step.components.execute"
                        :fieldsData="fieldsData"
                        :data="step"
                        :field="step"
                        :form="splitedDatatableRows[scope.row.keyIndex]"
                        :parentFormData="form"
                        :autoFillEntityData="
                          step.type == 'ENTITY' &&
                          step.entity_id &&
                          entityDataMapping &&
                          entityDataMapping[step.entity_id]
                            ? entityDataMapping[step.entity_id]
                            : null
                        "
                        :autoFillPrefeeredEntityData="
                          entityPrefeeredDataMapping &&
                          entityPrefeeredDataMapping[scope.row.keyIndex]
                            ? entityPrefeeredDataMapping[scope.row.keyIndex][
                                step.key
                              ] || 'NOT_APPLY'
                            : null
                        "
                        @applyFormRules="
                          checkForRowUpdate(step, scope.row.keyIndex)
                        "
                      >
                      </component>
                      <div class="d-flex" v-else>
                        <div
                          class="pl-15"
                          v-if="
                            scope.row &&
                            scope.row[`${step.id}_code`] &&
                            scope.row[`${step.id}_code`].formatInternational
                          "
                        >
                          {{ scope.row[`${step.id}_code`].formatInternational }}
                        </div>

                        <div
                          class="pl-15"
                          v-else-if="
                            scope.row &&
                            scope.row[`${step.id}_currency`] &&
                            scope.row[`${step.id}_currency`]
                          "
                        >
                          <span
                            v-if="scope.row[`${step.id}_currency`] === 'USD'"
                          >
                            {{
                              Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(scope.row[step.id])
                            }}
                          </span>
                          <span
                            v-else-if="
                              scope.row[`${step.id}_currency`] === 'INR'
                            "
                          >
                            {{
                              Intl.NumberFormat("en-IN", {
                                style: "currency",
                                currency: "INR",
                              }).format(scope.row[step.id])
                            }}
                          </span>
                          <span
                            v-else-if="
                              scope.row[`${step.id}_currency`] === 'EUR'
                            "
                          >
                            {{
                              Intl.NumberFormat("en-IE", {
                                style: "currency",
                                currency: "EUR",
                              }).format(parseFloat(num).toFixed(2))
                            }}
                          </span>
                          <span
                            v-else-if="
                              scope.row[`${step.id}_currency`] === 'CAD'
                            "
                          >
                            {{
                              Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "CAD",
                              }).format(scope.row[step.id])
                            }}
                          </span>
                          <span
                            v-else-if="
                              scope.row[`${step.id}_currency`] === 'AUD'
                            "
                          >
                            {{
                              Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "AUD",
                              }).format(scope.row[step.id])
                            }}
                          </span>
                        </div>
                        <div v-else-if="step && step.type == 'DATE'">
                          {{
                            getTableValue(scope.row, step) | globalDateFormat
                          }}
                        </div>
                        <div v-else-if="step && step.type == 'DATE_TIME'">
                          {{
                            getTableValue(scope.row, step)
                              | moment("MM-DD-YYYY hh:mm:ss A")
                          }}
                        </div>
                        <div v-else-if="step && step.type == 'FILE'">
                          <div
                            v-if="
                              getTableValue(scope.row, step) &&
                              getTableValue(scope.row, step).length &&
                              getTableValue(scope.row, step).length <= 1
                            "
                          >
                            <el-button
                              type="text"
                              style="margin-top: -14px"
                              class="mb-1"
                            >
                              <p style="font-size: 13px">
                                {{getFieldValue(scope.row, step)[0].name | truncate(20, "...")}}<i class="el-icon-download"></i>
                              </p>
                            </el-button>
                          </div>
                          <div
                            v-else-if="
                              getTableValue(scope.row, step) &&
                              getTableValue(scope.row, step).length &&
                              getTableValue(scope.row, step).length >= 1
                            "
                          >
                            <p
                              v-for="(file, index) of getTableValue(
                                scope.row,
                                step
                              )"
                              :key="index"
                            >
                              <el-button
                                type="text"
                                style="margin-top: -35px; margin-bottom: -10%"
                              >
                                <p style="font-size: 13px">
                                  {{ file.name | truncate(10, "...")
                                  }}<i class="el-icon-download"></i>
                                </p>
                              </el-button>
                              <br />
                            </p>
                          </div>
                          <!-- </p> -->
                        </div>
                        <div
                          v-else-if="
                            step &&
                            step.type == 'DATE_TIME_RANGE' &&
                            scope.row &&
                            scope.row[`${step.id}_from`]
                          "
                        >
                          {{
                            scope.row[`${step.id}_from`]
                              | moment("MM-DD-YYYY hh:mm:ss A")
                          }}
                          (From ~ To)
                          {{
                            scope.row[`${step.id}_to`]
                              | moment("MM-DD-YYYY hh:mm:ss A")
                          }}
                        </div>
                        <div v-else-if="step && step.type == 'TIME'">
                          {{ getTableValue(scope.row, step) | moment("hh:mm") }}
                        </div>
                        <div
                          v-else-if="
                            typeof getTableValue(scope.row, step) == 'object' &&
                            getTableValue(scope.row, step).lengths
                          "
                        >
                          {{ getTableValue(scope.row, step).join(",") }}
                        </div>
                        <div
                          v-else-if="step && step.type == 'AGGREGATE_FUNCTION'"
                        >
                          <p style="font-size: 13px">
                            {{
                              getCurrencyFormat(
                                getTableValue(scope.row, step),
                                step.template_id + "#" + step.id
                              )
                            }}
                          </p>
                        </div>
                        <div class="pl-15" v-else>
                          {{ getTableValue(scope.row, step) }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="currentEntity.name"
                    width="200"
                    fixed="right"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.parentDataName }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Action"
                    width="100"
                    v-if="splitedFields && splitedFields.length"
                    fixed="right"
                  >
                    <template slot-scope="scope">
                      <button
                        class="btn btn-sm btn-outline-primary m-lr-1"
                        v-if="
                          selectedDataTableRows.findIndex(
                            (e) => e.keyIndex == scope.row.keyIndex
                          ) !== -1
                        "
                        @click="showSplitingData(scope.row, scope.row.keyIndex)"
                      >
                        <i class="fa fa-bolt"></i> Set
                      </button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </div>
        </el-col>
      </el-row>
      <br />
      <el-row :gutter="5">
        <el-col :sm="24" class="text-right" v-if="showSplitDataSelection">
          <button
            @click="closeSpliteData"
            class="btn btn-sm btn-outline-danger m-lr-1"
          >
            <i class="fa fa-times"></i> Back
          </button>
          <span v-if="showSplitDataSelectionLoading">
            <button class="btn btn-sm btn-outline-primary m-lr-1">
              <i class="fa fa-loading fa-spain"></i> Save
            </button>
          </span>
          <span v-else>
            <button
              class="btn btn-sm btn-outline-primary m-lr-1"
              @click="checkSplittedData"
            >
              <i class="fa fa-paper-plane"></i> Save
            </button>
          </span>
        </el-col>
        <el-col :sm="24" class="text-right" v-else>
          <button
            class="btn btn-sm btn-outline-danger m-lr-1"
            @click="closeDialog"
          >
            <i class="fa fa-times"></i> Cancel
          </button>
          <button
            class="btn btn-sm btn-outline-primary m-lr-1"
            @click="checkReviewPage"
          >
            <i class="fa fa-sign-in"></i> Ok & Save
          </button>
        </el-col>
      </el-row>
      <div class="clearfix"></div>
      <br />
    </div>
  </div>
</template>

<script>
import AutomationHelper from "@/mixins/AutomationHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import { mapGetters } from "vuex";
import { dataTableAllowedExcecuteFields } from "../templates/formComponentsExecute/index";
import { postAPICall } from "@/helpers/httpHelper";
var fieldsList = require("../templates/fields.json");
export default {
  mixins: [AutomationHelper, CustomDashboardConfig, TemplateBuilderHelper],
  data() {
    return {
      loading: false,
      includeDataTable: false,
      viewType: "total",
      selectedRows: [],
      tableColums: [],
      selectedEntityFields: [],
      dataTableFields: [],
      dataTableData: [],
      dataTableColums: [],
      selectedDataTableRows: [],
      showSplitDataSelection: false,
      splitedDataRow: [],
      splitedDataRowIndex: "",
      splitedDatatableRows: {},
      refresh: false,
      entityDataMapping: {},
      entityPrefeeredDataMapping: {},
      search: "",
      fieldRules: [],
      fieldRulesFields: [],
      hideFields: [],
      disabledFields: [],
      showSplitDataSelectionLoading: false,
      fieldActions: [],
      fieldComponentsMap: {}
    };
  },
  props: [
    "currentEntity",
    "data",
    "fieldsData",
    "field",
    "form",
    "resetViewType",
  ],
  mounted() {
    this.loading = true;
    this.addTableColums();
    this.addSelectExistedData();
    if (fieldsList) {
      this.fieldActions = [
        ...fieldsList.allFormFields,
        ...fieldsList.allContentFields,
        ...fieldsList.allAdvancedFields,
      ];
    }
    this.fieldActions.forEach((field) => {
        if (!this.fieldComponentsMap[field.inputType]) {
          this.fieldComponentsMap[field.inputType] = field.components;
        }
      });
    this.loading = false;
  },
  watch: {
    resetViewType() {
      this.viewType = "total";
    },
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityPrimaryLabels",
    ]),
    getDataTableColumFields() {
      return (this.fieldsData || []).filter(
        (e) =>
          !this.field.show_only_totals &&
          e.relationship_key == this.field.key &&
          e.inputType == "DATA_TABLE"
      );
    },
    getCurrentDataTableData() {
      let result = [];
      if (this.viewType != "total") {
        let selectedField = this.fieldsData.find((e) => e.key == this.viewType);
        if (selectedField?.global_variable_entity_select_type_field_key) {
          (this.data || []).forEach((data) => {
            if (
              data?.entityData?.[
                selectedField.global_variable_entity_field_template_id
              ]?.[selectedField.global_variable_entity_select_type_field_key]
            ) {
              let dt =
                data?.entityData?.[
                  selectedField.global_variable_entity_field_template_id
                ]?.[selectedField.global_variable_entity_select_type_field_key];
              dt = dt.map((e) => {
                e.parentDataId = data._id;
                e.parentDataName = this.getPrimaryName(
                  this.currentEntity.primaryFields[0],
                  data
                );
                let selectedField = this.fieldsData.find(
                  (e) => e.key == this.viewType
                );
                e = this.mapDefaultValues(
                  selectedField && selectedField.data_table_columns
                    ? selectedField.data_table_columns
                    : [],
                  e
                );
                return e;
              });
              if (
                selectedField.data_table_filters &&
                selectedField.data_table_filters.length
              ) {
                dt = this.checkFilterConditions(
                  dt,
                  selectedField.data_table_filters,
                  selectedField.data_table_filter_query || "AND",
                  selectedField.data_table_columns
                );
              }
              result = [...result, ...dt];
            }
          });
        }
        if (
          this.form &&
          selectedField?.key &&
          this.form[selectedField.key] &&
          this.form[selectedField.key].length
        ) {
          result = result.map((e) => {
            let ex = this.form[selectedField.key].find(
              (el) =>
                el.keyIndex == e.keyIndex &&
                !e.newlyAddedData &&
                !el.newlyAddedData
            );
            if (ex) {
              return ex;
            }
            return e;
          });
          let r = result.filter(
            (e) =>
              this.form[selectedField.key]
                .flatMap((e) => e.keyIndex)
                .indexOf(e.keyIndex) != -1
          );
          this.selectTableRows(r);
        }
      }
      return result;
    },
    getDataTableColums() {
      if (this.viewType != "total") {
        let selectedField = this.fieldsData.find((e) => e.key == this.viewType);
        return (
          selectedField && selectedField.data_table_columns
            ? selectedField.data_table_columns
            : []
        )
          .filter((e) => e?.properties?.filed_content != "Hide")
          .map((e) => {
            e.id = e.key;
            e.type = e.input_type;
            e.components = this.fieldComponentsMap[e.input_type]
            return e;
          });
      }
      return [];
    },
    splitedFields() {
      let selectedField = this.fieldsData.find((e) => e.key == this.viewType);
      let splitedFields = (selectedField.data_table_columns || []).filter(
        (data) =>
          data.show_in_split && data?.properties?.filed_content != "Hide"
      );
      return splitedFields;
    },
    getDataTableData() {
      if (this.search) {
        return this.dataTableData.filter((e) => {
          return JSON.stringify(e)
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      }
      return this.dataTableData.filter((e) => !e.newlyAddedData);
    },
  },
  methods: {
    selectTableRows(result) {
      setTimeout(() => {
        result
          .filter((e) => !e.newlyAddedData)
          .forEach((row) => {
            this.$refs.multipleDataTable.toggleRowSelection(row);
          });
      }, 100);
    },
    getColumnWidth(step) {
      if (step.type === 'ENTITY') {
        return 220;
      }else if (step.type === 'DATE_RANGE' || step.type === 'DATE_TIME_RANGE' ) {
        return 400; 
      }
      return step.label.length > 12 ? step.label.length * 10 : 150;
    },
    checkForRowUpdate(step, key) {
      let index = this.dataTableData.findIndex(
        (e) => e.keyIndex == key && !e.newlyAddedData
      );
      if (
        index > -1 &&
        this.splitedDatatableRows?.[key]?.[step.key] !==
          this.dataTableData?.[index]?.[step.key]
      ) {
        if (this.fieldRulesFields.indexOf(step.key) !== -1) {
          this.applyRulesOnDataTableFields(
            this.dataTableColums,
            this.fieldRules,
            index,
            false,
            true
          );
        }
        if (step?.field_operation == "SUM" && step.show_in_split && key) {
          this.dataTableData[index][step.key] =
            this.splitedDatatableRows[key][step.key];
          let selectedData = this.selectedDataTableRows;
          this.dataTableData = this.dataTableData.filter((e) => {
            if (e.keyIndex == key) {
              return !e.newlyAddedData;
            } else {
              return true;
            }
          });
          this.selectTableRows(selectedData);
        }
      }
    },
    addSelectExistedData() {
      if (this.viewType != "total") {
        // console.log("dddddd", this.form[this.viewType]);
      }
    },
    selectedRowSplittedData(row) {
      if (row?.keyIndex) {
        return this.dataTableData.filter(
          (e) => e.newlyAddedData && e.keyIndex == row.keyIndex
        );
      }
      return [];
    },
    selectedRowSplittedDataByField(row, key) {
      if (row?.keyIndex) {
        return this.selectedRowSplittedData(row).filter((e) => e[key]);
      }
      return [];
    },
    checkSplittedData() {
      let errormessage = "";
      this.showSplitDataSelectionLoading = true;
      this.splitedFields.forEach((fl) => {
        if (fl?.field_operation) {
          let index = this.dataTableData.findIndex(
            (e) => !e.newlyAddedData && e.keyIndex == this.splitedDataRowIndex
          );
          let originalValue = this.dataTableData[index][fl.key];
          let tableValue = this.dataTableColumvalue(
            this.splitedDataRow,
            fl.key,
            fl.operation
          );
          if (originalValue != tableValue) {
            if (fl?.field_operation == "SUM") {
              errormessage =
                fl.label +
                " has to be equal to original " +
                fl.label +
                " value";
            }
          }
        }
        let emptyRow = this.splitedDataRow.find(
          (el) => !el[fl.key] || el[fl.key] == ""
        );
        if (emptyRow) {
          errormessage = "Please fill " + fl.label;
        }
      });
      if (errormessage) {
        this.showMessage(errormessage, "warning");
        this.showSplitDataSelectionLoading = false;
      } else {
        this.addSplitToOrignal();
      }
    },
    addSplitToOrignal() {
      if (this.splitedDataRowIndex != "") {
        this.dataTableData = this.dataTableData.filter(
          (e) =>
            !e.newlyAddedData ||
            (e.newlyAddedData && e.keyIndex != this.splitedDataRowIndex)
        );
        let index = this.dataTableData.findIndex(
          (e) => !e.newlyAddedData && e.keyIndex == this.splitedDataRowIndex
        );
        if (this.splitedDataRow.length == 1) {
          this.dataTableData[index] = {
            ...this.dataTableData[index],
            ...this.splitedDataRow[0],
          };
          this.splitedDatatableRows[this.splitedDataRowIndex] = {
            ...this.dataTableData[index],
          };
        } else {
          this.splitedDataRow.forEach((e) => {
            let r = {
              ...this.dataTableData[index],
              ...e,
              ...{ newlyAddedData: true },
            };
            this.dataTableData.push(JSON.parse(JSON.stringify(r)));
          });
        }
      }
      this.closeSpliteData();
    },
    dataTableColumvalue(data, column, operation) {
      let result = 0;
      if (data.length) {
        data.forEach((e) => {
          if (e[column]) {
            result = result + parseInt(e[column]);
          }
        });
      }
      if (operation == "AVERAGE") {
        return result / data.length;
      }
      return result;
    },
    deleteRow(i) {
      this.splitedDataRow.splice(i, 1);
    },
    addNewRow() {
      let index = this.dataTableData.findIndex(
        (e) => !e.newlyAddedData && e.keyIndex == this.splitedDataRowIndex
      );
      let data = {};
      this.splitedFields.forEach((fl) => {
        if (fl?.field_operation) {
          let index = this.dataTableData.findIndex(
            (e) => !e.newlyAddedData && e.keyIndex == this.splitedDataRowIndex
          );
          let originalValue = this.dataTableData[index][fl.key];
          let tableValue = this.dataTableColumvalue(
            this.splitedDataRow,
            fl.key,
            fl.operation
          );
          if (originalValue > tableValue) {
            data[fl.key] = originalValue - tableValue;
          } else if (originalValue <= tableValue) {
            data[fl.key] = 0;
          }
        }
      });
      this.splitedDataRow.push({
        newlyAddedData: true,
        ...this.dataTableData[index],
        ...data,
      });
    },
    closeSpliteData() {
      this.splitedDataRow = [];
      this.splitedDataRowIndex = "";
      this.showSplitDataSelectionLoading = false;
      this.showSplitDataSelection = false;
      if (this.selectedDataTableRows.length) {
        setTimeout(() => {
          let selectedData = this.selectedDataTableRows.flatMap(
            (e) => e.keyIndex
          );
          this.getDataTableData
            .filter((e) => selectedData.indexOf(e.keyIndex) !== -1)
            .forEach((row) => {
              this.$refs.multipleDataTable.toggleRowSelection(row);
            });
        }, 100);
      }
    },
    readonly(data) {
      //If it's an entity varaible, have to check access
      if (data.field_assignable === "read_only" && data.is_entity_variable) {
        return true;
      }
      if (
        data &&
        data.properties &&
        data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    showSplitingData(data, keyIndex) {
      let existedData = this.selectedRowSplittedData(data);
      this.mapSplitedDataToTable();
      let index = this.dataTableData.findIndex(
        (e) => e.keyIndex == keyIndex && !e.newlyAddedData
      );
      if (existedData.length) {
        this.splitedDataRow = [...existedData];
      } else {
        let dt = { ...data, ...this.splitedDatatableRows[keyIndex] };
        this.splitedDataRow = [dt];
        this.dataTableData[index] = { ...this.dataTableData[index], ...dt };
      }
      this.splitedDataRowIndex = keyIndex;
      this.showSplitDataSelection = true;
    },
    mapSplitedDataToTable() {
      (Object.keys(this.splitedDatatableRows) || []).forEach((keyIndex) => {
        let index = this.dataTableData.findIndex(
          (e) => e.keyIndex == keyIndex && !e.newlyAddedData
        );
        let dt = { ...this.splitedDatatableRows[keyIndex] };
        this.dataTableData[index] = { ...this.dataTableData[index], ...dt };
      });
    },
    getPrimaryName(primaryField, item) {
      let name = "";
      let key =
        primaryField && primaryField["key"] ? primaryField["key"] : null;
      if (key) {
        if (key.includes("#")) {
          key = key.split("#")[1];
        }
        name =
          item &&
          item.entityData &&
          primaryField.template_id &&
          item.entityData[primaryField.template_id] &&
          item.entityData[primaryField.template_id][key]
            ? item.entityData[primaryField.template_id][key + "/name"] ||
              item.entityData[primaryField.template_id][key]
            : "-";
        if (
          !name &&
          item &&
          item.entity_prime_data &&
          item.entity_prime_data[key]
        ) {
          name =
            item.entity_prime_data[key + "/name"] ||
            item.entity_prime_data[key];
        }
        if (
          primaryField?.inputType == "WEEKDAYS" &&
          this.weekDayMap &&
          this.weekDayMap[name]
        ) {
          name = this.weekDayMap[name];
        }
      }
      if (name && typeof name == "object" && name.length) {
        return name.join(",");
      }
      return name;
    },
    mapFiltersWithData(filters, form, parentFormData) {
      return filters.map((e) => {
        if (e.field !== "UNIQUE" && e.unique_field) e.unique_field = "";
        if (e && e.data_source == "self_field" && e.value_field && !e.value) {
          e.value = form[e.value_field];
          if (
            parentFormData &&
            parentFormData[e.value_field] &&
            !form[e.value_field]
          ) {
            e.value = parentFormData[e.value_field];
          }
        }
        return e;
      });
    },
    async configDataTableFields() {
      this.dataTableColums = this.getDataTableColums;
      this.dataTableData = this.getCurrentDataTableData;
      if (this.viewType != "total") {
        let selectedField = this.fieldsData.find((e) => e.key == this.viewType);
        this.fieldRules = [];
        this.fieldRulesFields = [];
        if (selectedField?.rules) {
          this.fieldRules = selectedField.rules;
          this.fieldRules.forEach((e) => {
            if (e?.conditions) {
              this.fieldRulesFields = [
                ...this.fieldRulesFields,
                ...e.conditions.flatMap((el) => el.selectField),
              ];
            }
          });
        }
      }
      let entityFields = this.dataTableColums.filter(
        (e) => e.inputType == "ENTITY" && e.show_in_split
      );
      if (entityFields && entityFields.length) {
        this.loading = true;
        this.refresh = true;
        await Promise.all(
          entityFields.map(async (el) => {
            if (el && el.filters) {
              let realFilters = el.filters.filter((e) => !e.preferred_filter);
              let preferredRelationshipFilters = [],
                checkRelationships = false;
              if (
                el.filters
                  .filter((e) => e.preferred_filter)
                  .every(
                    (e) =>
                      e.data_type == "RELATION" &&
                      e.relation_entity_id &&
                      !this.form[e.value_field]
                  )
              ) {
                preferredRelationshipFilters = el.filters
                  .filter(
                    (e) =>
                      e.preferred_filter &&
                      e.data_type == "RELATION" &&
                      e.relation_entity_id &&
                      !this.form[e.value_field]
                  )
                  .flatMap((e) => e.relation_entity_id);
                checkRelationships = true;
              }
              let params = {
                entity_id: el.entity_id,
                template_fields_data: [],
                filters: this.mapFiltersWithData(realFilters, {}, this.form),
                relationship: null,
                limit: 500,
                page: 1,
                relational_entities: preferredRelationshipFilters,
              };
              if (
                this.currentEntity &&
                this.currentEntity.created_by &&
                this.currentEntity.company_id
              ) {
                params.user_id = this.currentEntity.created_by;
                params.company_id = this.currentEntity.company_id;
              }
              // await this.$store.dispatch(
              //   "entities/fetchEntityRecordsForTable",
              //   params
              // );
              const response = await postAPICall('POST', '/entities-data/entity/data', params);
              if (
                response &&
                response.data
              ) {
                if (checkRelationships) {
                  let relatedFilters = el.filters.filter(
                    (e) =>
                      e.preferred_filter &&
                      e.data_type == "RELATION" &&
                      e.relation_entity_id &&
                      !this.form[e.value_field]
                  );
                  this.dataTableData = this.dataTableData.map((data) => {
                    (relatedFilters || []).forEach((filter) => {
                      let relatedData = response.data.find(
                        (e) => {
                          if (
                            e &&
                            e.relation_data &&
                            e.relation_data[filter.relation_entity_id] &&
                            e.relation_data[filter.relation_entity_id].indexOf(
                              data[filter.value_field]
                            ) != -1
                          ) {
                            return true;
                          }
                        }
                      );
                      let value = "";
                      if (relatedData?._id) {
                        value = relatedData._id;
                      }
                      if (this.entityPrefeeredDataMapping[data.keyIndex]) {
                        this.entityPrefeeredDataMapping[data.keyIndex] = {
                          ...this.entityPrefeeredDataMapping[data.keyIndex],
                          ...{
                            [el.key]: value,
                          },
                        };
                      } else {
                        this.entityPrefeeredDataMapping[data.keyIndex] = {
                          [el.key]: value,
                        };
                      }
                    });
                    return data;
                  });
                }
                this.entityDataMapping[el.entity_id] = {
                  ...response,
                  ...{ update: true },
                };
              }
            }
            return el;
          })
        );
        this.refresh = false;
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
      this.dataTableData.forEach((e) => {
        if (!e.newlyAddedData) {
          this.$set(
            this.splitedDatatableRows,
            e.keyIndex,
            JSON.parse(JSON.stringify(e))
          );
        }
      });
    },
    handleDataTableSelectionChange(rows) {
      this.selectedDataTableRows = rows;
    },
    checkMultiSelect() {
      if (!this.field?.allow_multiple) {
        this.$refs.multipleEntityTable.clearSelection();
        this.showMessage(
          "You are allowed to select only one " + this.field.label,
          "warning"
        );
      }
    },
    checkSingleSelect(val) {
      if (!this.field?.allow_multiple && val && val.length > 1) {
        this.$refs.multipleEntityTable.toggleRowSelection(val[0]);
      }
    },
    handleSelectionChange(rows) {
      this.selectedRows = rows;
    },
    getPropValue(step) {
      if (step.type == "ENTITY") {
        return step.template_id + "#" + step.id + "/name";
      }
      return step.template_id + "#" + step.id;
    },
    addTableColums() {
      this.selectedEntityFields = this.getAllFieldsFromEntity(
        this.currentEntity,
        false
      );
      this.selectedEntityFields = this.selectedEntityFields.map((data) => {
        if (data.key && data.key.includes("#")) {
          data.key = data.key.split("#")[1];
        }
        return data;
      });
      this.selectedEntityFields.forEach((data) => {
        if (data.inputType == "DATA_TABLE") {
          this.dataTableFields.push(data);
        } else {
          let k = data.key;
          if (data.key && data.key.includes("#")) {
            k = data.key.split("#")[1];
          }
          this.tableColums.push({
            label: data.label,
            id: k,
            template_id: data.template_id,
            type: data.input_type,
            inputType: data.inputType,
          });
        }
      });
    },
    closeDialog() {
      this.loading = false;
      this.viewType = "total";
      this.$emit("close");
    },
    showMessage(message, type) {
      this.$message({
        message: message,
        type: type,
      });
    },
    checkReviewPage() {
      if (this.field?.validations?.required) {
        if (this.viewType == "total") {
          if (!this.selectedRows.length) {
            this.showMessage("Please select atleast one ", "warning");
            return;
          }
        } else {
          if (!this.selectedDataTableRows.length) {
            this.showMessage("Please select atleast one ", "warning");
            return;
          }
        }
      }
      this.saveSelectedData();
    },
    saveSelectedData() {
      this.loading = true;
      if (this.viewType == "total") {
        this.$emit(
          "rows-selected",
          this.selectedRows.flatMap((e) => e._id) || [],
          false,
          this.getDataTableColumFields
        );
      } else {
        let data = [],
          splitedDatatableRows = JSON.parse(
            JSON.stringify(this.splitedDatatableRows)
          );
        let selectedData = [];
        this.selectedDataTableRows.forEach((el) => {
          let found = selectedData.find(
            (e) =>
              e.keyIndex == el.keyIndex &&
              !e.newlyAddedData &&
              !el.newlyAddedData
          );
          if (!found) {
            selectedData.push(el);
          }
        });
        selectedData.forEach((e) => {
          let modifiedDataIndex = Object.keys(splitedDatatableRows).find(
            (dt) => dt == e.keyIndex
          );
          if (modifiedDataIndex) {
            // let fieldsKeys = this.dataTableColums
            //   .filter((step) => {
            //     if (
            //       !step.show_in_split &&
            //       (!step?.properties?.filed_content ||
            //         step?.properties?.filed_content == "") &&
            //       !step.editable_detailed_view &&
            //       step.field_assignable != "read_only"
            //     ) {
            //       return true;
            //     }
            //   })
            //   .flatMap((e) => e.key);
            data.push(splitedDatatableRows[modifiedDataIndex]);
          } else {
            data.push(e);
          }
          let splitedData = this.dataTableData.filter(
            (el) => el.keyIndex == e.keyIndex && el.newlyAddedData
          );
          data = [...data, ...splitedData];
        });
        this.setEntityLabels(data || []);
        this.$emit("data-table-selected", data || [], this.viewType);
      }
      this.closeDialog();
    },
    setEntityLabels(data) {
      let obj = { ...(this.getEntityPrimaryLabels || {}) };
      data.forEach((dt) => {
        if (!dt.newlyAddedData) {
          Object.keys(dt || {}).forEach((key) => {
            if (dt[key] && dt[key + "/name"]) {
              obj[dt[key]] = dt[key + "/name"];
            }
          });
        }
      });
      this.$store.commit("entities/setEntityPrimaryLabels", obj, {
        root: true,
      });
    },
    mergeObjects(ob1, ob2, excludedProperties) {
      let obj1 = JSON.parse(JSON.stringify(ob1));
      let obj2 = JSON.parse(JSON.stringify(ob2));
      const mergedObj = {};
      for (let prop in obj1) {
        this.$set(mergedObj, prop, obj1[prop]);
      }
      for (let prop in obj2) {
        if (excludedProperties.includes(prop)) {
          continue;
        }
        this.$set(mergedObj, prop, obj2[prop]);
      }
      return mergedObj;
    },
  },
  components: {
    // AnyFieldsExecute: () =>
    //   import(
    //     "@/components/templates/formComponentsExecute/AnyFieldsExecute.vue"
    //   ),
      ...dataTableAllowedExcecuteFields,
  },
};
</script>

<style lang="scss" scoped>
.prevent-wrap {
  white-space: nowrap;
}

.content-table {
  table,
  th,
  td {
    border: 1px solid rgb(227, 227, 227);
    border-collapse: collapse;
    padding: 10px;
  }
}

.popup {
  margin-left: 80%;
  padding: 8px;
}

.sidemenu {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
  //  direction: rtl;
}

// .sidemenu .el-table__fixed-body-wrapper {
//     left: 0;
//     top: x;
//     overflow-y: auto;
//     z-index: 3;
// }

.sidemenu::-webkit-scrollbar {
  width: 1em;
  height: 0.5em;
}

.sidemenu::-webkit-scrollbar-horizontal {
  /* Select the down or left scroll button when it's being hovered by the mouse */
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

.sidemenu::-webkit-scrollbar-button:single-button {
  width: 16px;
  height: 13px;
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement {
  // border-width: 8px 8px 8px 8px;
  // border-color: transparent transparent #555555 transparent;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}

/* Down */
.sidemenu::-webkit-scrollbar-button:single-button:horizontal:increment {
  //  border-width: 8px 8px 8px 8px;
  // border-color: #555555 transparent transparent transparent;
}

.sidemenu::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

// ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
//   overflow-x: auto;
// }

.scrollable-div {
  max-height: 44vh;
  overflow-y: auto;
  scrollbar-width: thin;
  border: 1px solid #ccc;
  /* Optional: Add a border for better visibility */
}

/* WebKit Scrollbar Styling */
.scrollable-div::-webkit-scrollbar {
  width: 10px;
}

.scrollable-div::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background-clip: padding-box;
  border: 10px solid rgba(0, 0, 0, 0.19);
}

.content-table-scroll::-webkit-scrollbar {
  width: 10px;
}

.content-table-scroll::-webkit-scrollbar-thumb {
  background-color: #888;
  /* or any color you prefer for the scrollbar thumb */
  border-radius: 5px;
  /* round the corners of the thumb */
}

.content-table-scroll::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* or any color you prefer for the scrollbar track */
}
</style>
